import React, {useState} from 'react';
import {Link} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import {Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel} from 'react-accessible-accordion';
import PopularExample from '../../components/Examples/PopularExample';
import WrapperStyle from './examples.style';
import Drawer from '../../components/UiComponent/drawer';
import ScriptTag from 'react-script-tag';
import img8 from '../../assets/images/about/about-img8.png';
import shape1 from '../../assets/images/about/about-shape1.png';

const OurSolutions = () => {
    const [visible, setVisible] = useState(false);
    const [promotionId, setPromotionId] = useState(null);
    const [drawerTitle, setDrawerTitle] = useState(null);
    const showDrawer = (id, title) => {
        setVisible(true);
        setPromotionId(id);
        setDrawerTitle(title)
    };
    return (
      <WrapperStyle>
        <section className="solutions-area pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <h1>プリアド・プリセーブ
                    </h1>
                    <p>
                        AppleMusicのプリアド(Pre-Add)、Spotifyのプリセーブ(Pre-Save)機能を使用してアーティストのリリースキャンペーンを行うことができます。
                        リタッチの多数の応募方法を組み合わせて、魅力的な賞品を提供すれば、多くのプリアド・プリセーブを獲得できます。
                    </p>
                </div>
                <div className="row">
                    <div className="col-lg-12 col-sm-12">
                        <a data-retach-widget="embed" href="https://retach.biz/promotions/c9b19a2ff3f00a9eaf" rel="nofollow">リタッチ</a>
                        <ScriptTag  type="text/javascript" src="https://retach.biz/scripts/promotionCall.js"/>
                    </div>
                </div>
            </div>
        </section>
          <section className="about-area res-pt-0 pb-100 pt-70 bg-fafafb">
              <div className="container">
                  <div className="row align-items-center">
                      <div className="col-lg-6 col-md-12">
                          <div className="about-content">
                              <div className="content">
                                  <h2>漫画でわかる<br/>プリアド・プリセーブ</h2>
                                  <p>Apple MusicのプリアドとSpotifyのプリセーブマーケティングを漫画でわかりやすく解説します。</p>
                                  <Link to="/examples/pre-add-pre-save/manga" className="default-btn">
                                      <i className="flaticon-right"></i>
                                      漫画を見る <span></span>
                                  </Link>
                              </div>
                          </div>
                      </div>
                      <div className="col-lg-6 col-md-12">
                          <div className="about-img" style={{width: '550px'}}>
                              <StaticImage src="../../../assets/images/examples/preAddPreSave/manga/2p.jpg" />
                          </div>
                      </div>


                  </div>
              </div>
          </section>

          <section className="ptb-100 top-question" >
              <div className="container">
                  <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="features" />
                        Question
                    </span>
                      <h2 id="question">よくあるご質問</h2>
                  </div>
                  <div className="faq-accordion">
                      <Accordion allowMultipleExpanded={true}>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      無料でためすことはできますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      アカウント登録でリタッチの基本機能を確認できますが、プリアド・プリセーブ機能はベーシックプラン以上が必要です。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      1ヶ月だけ使用することはできますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      はい。次回の更新日までにプランをキャンセルしてください。キャンセルしない場合は自動更新となります。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      プランを変更したらどうなりますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      すぐに新しいプランに切り替わります。旧プランの残日数は日割りでのご返金となりますが、ダウングレードの場合は日割りでのご返金はございません。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      プリアド・プリセーブと組み合わせる応募方法に制限はありますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      はい。プランによる制限については<Link href="/pricing">料金ページ</Link>をご確認ください。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                              <AccordionItemHeading>
                                  <AccordionItemButton>
                                      応募者の住所や電話番号を取得できますか？
                                  </AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                  <p>
                                      はい。応募者に入力して頂く項目は自由に変更できます。応募者情報の設定についてはこちらをご覧ください。
                                  </p>
                              </AccordionItemPanel>
                          </AccordionItem>
                      </Accordion>
                  </div>
              </div>
          </section>
          <PopularExample exclude={['preAddPreSave']} showDrawer={showDrawer}/>
          <Drawer visible={visible}id={promotionId} drawerTitle={drawerTitle} setVisible={setVisible}/>
      </WrapperStyle>
    )
};

export default OurSolutions