import React from 'react'
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import ContentBanner from '../../../components/Common/ContentBanner'
import Footer from "../../../components/App/Footer"
import PreAddPreSaveCampaign from '../../../components/Examples/PreAddPreSaveCampaign'
import Helmet from 'react-helmet';
import ProjectStartArea from '../../../components/Common/StartProject';
import SEO from '../../../components/App/SEO';
import exampleMetaImage from '../../../assets/images/examples/preAddPreSave/example-pre-add-pre-save.png';
// Functional component
const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="プリアド・プリセーブキャンペーン"
		  desc="AppleMusicのプリアド(Pre-Add)、Spotifyのプリセーブ(Pre-Save)機能を使用してアーティストのリリースキャンペーンを行うことができます。"
		  banner={exampleMetaImage}
		  pathname={'/examples/pre-add-pre-save'}
		  type={'Article'}
		  shortName="プリアド・プリセーブキャンペーン"
		  createdAt="2021-03-02"
		/>
	  <Navbar />
	  <ContentBanner
		  pageTitle="プリアド・プリセーブ"
		homePageText="Home"
		homePageUrl="/"
		activePageText="プリアド・プリセーブ"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		}]}
	  />
	  <PreAddPreSaveCampaign />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples